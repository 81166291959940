// src/utils/cookie.js
// 设置 cookie
let Cookie = {
  /**
   * @description 设置Cookie
   * @param {name} 设置得Cooke名称
   * @param {value} 设置Cooke值
   * @param {days} 过期日期
   */
  setCookie: (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  },
  /**
   * @description 获取Cookie
   * @param {name} 获取得Cooke名称
   */
  getCookie: (name) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  /**
  * @description 删除Cookie
  * @param {name} 删除得Cooke名称
  */
  deleteCookie: (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
  },
}







export default Cookie