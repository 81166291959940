/*
 * @Author: Suroc
 * @Date: 2024-06-21 14:33:49
 * @LastEditTime: 2024-07-16 17:01:15
 * @Description: 全局注册
 */
import Tool from '@/utils/tool';
import Cookie from '@/utils/cookie';
import Https from '@/config/apis/https';


export default {
  install(app) {
    app.config.globalProperties.$Tool = Tool;
    app.config.globalProperties.$Axios = Https;
    app.config.globalProperties.$Cookie = Cookie;
  }
};
