/*
 * @Author: Suroc
 * @Date: 2024-06-24 11:55:44
 * @LastEditTime: 2024-06-24 14:43:38
 * @Description: 公共函数
 */
import { Toast } from "vant";

let Tool = {
  /**
   * @description 全局消息提示
   * @param {msg} 提示内容
   */
  Toasts: (msg) => {
    Toast(msg);
  }
};


export default Tool