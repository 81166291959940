
import "vant/es/toast/style";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./config/router";
import suroc from './suroc'
import "./style/index.less";

const app = createApp(App)
app.use(suroc)
app.use(router)
app.mount("#app");