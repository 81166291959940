/*
 * @Author: Suroc
 * @Date: 2024-06-12 09:44:21
 * @LastEditTime: 2024-08-05 20:46:34
 * @Description: 路由
 */
export default [
  {
    path: "/",
    redirect: "/download",
  },
  {
    path: "/download",
    component: () => import("@/pages/download.vue"),
  },
  {
    path: "/find",
    component: () => import("@/pages/find.vue"),
  },
  {
    path: "/treasure",
    component: () => import("@/pages/treasure.vue"),
  },
];
